export const moreIcon = (
  <svg width="10" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m1.5.54 7 7-7 7"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
