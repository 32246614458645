import MetaData from "@components/Modules/MetaData";
import { Button, Typography } from "@sphtech/dmg-design-system";
import { ReactElement } from "react";

import PubBaseLayout from "../BaseLayout";
import { configSite } from "../config";
import styles from "./aboutus.module.css";
import shareImagePath from "./assets/hw-about-us-1.jpg?url";
import hwAboutUs2 from "./assets/hw-about-us-2.jpg";

const { Body, Heading } = Typography;

export default function AboutUsPage(): ReactElement {
  return (
    <PubBaseLayout ads={false}>
      <MetaData
        title="About Us"
        type="WebPage"
        path="/about-us"
        image={{
          src: `${configSite.address}${shareImagePath}`,
          alt: "",
          srcWidth: 1920,
          srcHeight: 941,
        }}
      />

      <div className={styles.heroContainer}>
        <h3>Get to Know Us.</h3>
      </div>

      <div className={styles.whoWeAreContainer}>
        <div className={styles.whoWeAreTitleContainer}>
          <Heading.SubHeading>Who We Are</Heading.SubHeading>
        </div>

        <Body>
          Useful. Inspiring. Empowering: These words capture the essence of what
          Her World means to the career-focused woman. Being your guide to
          achieving the best in life is at the heart of what we do, and we offer
          practical tips, solutions and advice that will not only help you live
          and work better, but also plan for the future.
        </Body>
        <br />
        <Body>
          Through our multi-channel platforms in web, social media, print,
          events and editorial initiatives, we are creating meaningful
          conversations that matter.
        </Body>
        <br />

        <div className={styles.whoWeAreLinkContainer}>
          <Button variant="secondary">ADVERTISING</Button>
          <Button variant="secondary">FEEDBACK</Button>
        </div>
      </div>

      <img src={hwAboutUs2} alt="hw-about-us-2.jpg" width="100%" />
    </PubBaseLayout>
  );
}
