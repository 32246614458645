import ContentHubStoryListingLayout from "@app/layouts/ContentHub/ContentHubStoryListingLayout";
import { TeaserDataApiResponse } from "@app/types/ServerCustomContext";
import { mapTeaserDataToLatestProps } from "@app/utils/mapTeaserDataToProps";
import { useInView } from "@components/hooks/hook";
import { Loader } from "@sphtech/dmg-design-system";
import { LOAD_MORE_TEXT } from "@src/pubs/common/config";
import { ReactElement, ReactNode, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useSWRInfinite from "swr/infinite";

type Props = {
  data: {
    limit: number;
    lb1?: ReactNode;
  };
  isLoadingSection: boolean;
  setIsLoadingSection: (isLoadingSection: boolean) => void;
};

/**
 * VideoStoryListing - Copied from ContentHubStoryListing and further customised.
 * This component is responsible for infinite scrolling of videoStories.
 */
export default function VideoStoryListing({
  data,
  isLoadingSection,
  setIsLoadingSection,
}: Props): ReactElement {
  const { ref, inView } = useInView({ threshold: 0.1 });
  const limit = data.limit;

  const [searchParams] = useSearchParams();
  const searchSection = searchParams.get("section");

  const getKey = (pageIndex: number) => {
    return `/_plat/api/teaser/latest?${[
      searchSection && `uniqSectionNames=${searchSection}`,
      `storyTypes=videoStory`,
      `from=${pageIndex * limit}`,
      `size=${limit}`,
    ]
      .filter(Boolean)
      .join("&")}`;
  };

  const {
    data: moreData,
    isLoading,
    size,
    setSize,
  } = useSWRInfinite<TeaserDataApiResponse>(getKey, {
    revalidateFirstPage: false,
    initialSize: 1,
  });

  const sizeLength = size - 1;
  const isLoadingMore =
    isLoading ||
    (size > 0 && moreData && typeof moreData[sizeLength] === "undefined");

  const hasMoreData = size === 0 || !!moreData?.[size - 1]?.results?.length;

  // When user scrolls to bottom of list
  useEffect(() => {
    // Set up debouncing to prevent multiple consecutive calls
    const timeout = setTimeout(() => {
      if (!inView || !hasMoreData) return;
      void setSize((prev) => {
        window.SPHMGTM.cmd.push(`pagination=${prev + 1}`);
        return prev + 1;
      });
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [inView, setSize, hasMoreData]);

  // When user switches tabs - Beauty, Fashion, etc.
  useEffect(() => {
    setIsLoadingSection(false);
  }, [moreData, setIsLoadingSection]);

  return (
    <>
      {moreData &&
        moreData.length > 0 &&
        moreData.map((el, index) => {
          if (!el?.results?.length) {
            return;
          }

          const elem = el.results.map((teaser) =>
            mapTeaserDataToLatestProps(teaser, "contentblockh"),
          );

          // When user switches tabs - Beauty, Fashion, etc.
          if (isLoadingSection) {
            return (
              <div style={{ minHeight: "1000px" }} key={index}>
                <Loader label={LOAD_MORE_TEXT} />
              </div>
            );
          }

          return (
            <div key={index}>
              {index > 0 && data.lb1}

              <ContentHubStoryListingLayout
                key={index}
                data={{
                  listedStories: elem,
                }}
                displayPlayIcon
              />
            </div>
          );
        })}

      <div ref={ref}>{isLoadingMore && <Loader label={LOAD_MORE_TEXT} />}</div>
    </>
  );
}
