export const VIDEO_LANDING_ITEMS = [
  {
    text: "All",
    url: "/video",
  },
  {
    text: "POV",
    url: "/video?section=pov",
  },
  {
    text: "Independence",
    url: "/video?section=independence",
  },
  {
    text: "Wellness",
    url: "/video?section=wellness",
  },
  {
    text: "Life",
    url: "/video?section=life",
  },
  {
    text: "Style",
    url: "/video?section=style",
  },
];

export const getNameFromUniq = (uniq: string | null) => {
  switch (uniq) {
    case "pov":
      return "POV";
    case "independence":
      return "Independence";
    case "wellness":
      return "Wellness";
    case "life":
      return "Life";
    case "style":
      return "Style";
    default:
      return "All";
  }
};
