const iosIcon = (
  <svg
    width="35"
    height="35"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="black" />
    <path
      d="M27.2701 23.1677C27.2909 21.5466 28.1615 20.0127 29.5427 19.1636C28.6714 17.9193 27.212 17.1303 25.6937 17.0828C24.0743 16.9128 22.5044 18.0518 21.6791 18.0518C20.8379 18.0518 19.5674 17.0997 18.1992 17.1278C16.4158 17.1855 14.7533 18.1994 13.8857 19.7585C12.0206 22.9876 13.4118 27.7333 15.1984 30.3435C16.0922 31.6216 17.1369 33.0493 18.5039 32.9987C19.8415 32.9432 20.3411 32.1457 21.9557 32.1457C23.5554 32.1457 24.0241 32.9987 25.4188 32.9665C26.8541 32.9432 27.7585 31.6827 28.621 30.3925C29.2632 29.4818 29.7574 28.4752 30.0853 27.4101C28.3985 26.6967 27.272 24.9992 27.2701 23.1677Z"
      fill="white"
    />
    <path
      d="M24.6357 15.3662C25.4183 14.4267 25.8039 13.2192 25.7105 12C24.5149 12.1256 23.4104 12.697 22.6172 13.6005C21.8416 14.4832 21.438 15.6695 21.5143 16.8421C22.7104 16.8544 23.8862 16.2985 24.6357 15.3662Z"
      fill="white"
    />
  </svg>
);

const androidIcon = (
  <svg
    width="35"
    height="35"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="black" />
    <path
      d="M14.4928 33C14.1642 32.836 14 32.4258 14 32.0157C14 31.9336 14 31.7695 14 31.6875C14 25.6172 14 19.5469 14 13.5586C14 13.2305 14.0821 12.9844 14.1642 12.6563C14.2464 12.4922 14.4106 12.3281 14.5749 12.1641C18.1064 15.6914 21.5557 19.2187 25.0051 22.7461C21.4736 26.0273 18.0243 29.4727 14.4928 33Z"
      fill="white"
    />
    <path
      d="M28.4543 19.1367C27.4688 20.1211 26.4011 21.1055 25.4156 22.1719C22.1305 18.8086 18.7632 15.4453 15.4781 12.082C15.4781 12.082 15.4781 12 15.5602 12C19.8309 14.3789 24.1015 16.7578 28.4543 19.1367Z"
      fill="white"
    />
    <path
      d="M15.4781 33C18.7632 29.7187 22.1305 26.4375 25.4156 23.1563C26.319 24.0586 27.3045 24.961 28.3722 25.9453C24.1015 28.3242 19.8309 30.7031 15.5602 33H15.4781Z"
      fill="white"
    />
    <path
      d="M28.9474 25.6172C27.8797 24.6328 26.8942 23.6484 25.8266 22.6641C26.8942 21.5977 27.9619 20.5313 29.0295 19.4648C29.358 19.6289 29.6865 19.793 30.0151 20.039C30.8363 20.5313 31.7398 20.9414 32.561 21.4336C32.8074 21.5156 32.9717 21.6797 33.1359 21.9258C33.4644 22.3359 33.4644 22.7461 33.1359 23.1563C32.9717 23.3203 32.8074 23.4844 32.561 23.5664C31.4113 24.3047 30.1793 24.961 28.9474 25.6172Z"
      fill="white"
    />
  </svg>
);

const facebookIcon = (
  <svg
    width="35"
    height="35"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="black" />
    <path
      d="M27.1504 23.9375L27.8247 19.7783H23.6105V17.0758C23.6105 15.9386 24.1971 14.8269 26.0851 14.8269H28V11.2811C26.874 11.1086 25.7412 11.0128 24.6017 11C21.1291 11 18.8568 12.9933 18.8568 16.6031V19.7783H15V23.9439H18.8568V34.0064H23.6037V23.9439H27.1437L27.1504 23.9375Z"
      fill="white"
    />
  </svg>
);

const linkedinIcon = (
  <svg
    width="35"
    height="35"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="black" />
    <path
      d="M16.7222 32H12.373V18.6476H16.7222V32ZM14.5442 16.8254C13.1456 16.8317 12 15.7587 12 14.4254C12 13.0921 13.1189 12 14.5176 12C15.9163 12 17.0552 13.0667 17.0618 14.4C17.0618 15.7333 15.9363 16.8127 14.5442 16.8254ZM33 32H28.6575V25.4984C28.6575 23.9492 28.6242 21.9619 26.393 21.9619C24.1618 21.9619 23.7821 23.6444 23.7821 25.3841V31.9937H19.4396V18.6476H23.6089V20.4698H23.6689C24.5214 19.0794 26.0999 18.254 27.785 18.3111C32.1874 18.3111 32.9933 21.073 32.9933 24.6603V32H32.9867H33Z"
      fill="white"
    />
  </svg>
);

const instagramIcon = (
  <svg
    width="35"
    height="35"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="black" />
    <path
      d="M28.2347 33.0713H15.7651C12.5861 33.0713 10 30.4686 10 27.2694V15.8734C10 12.674 12.5861 10.0713 15.7651 10.0713H28.2347C31.4139 10.0713 34 12.674 34 15.8734V27.2694C34 30.4686 31.4139 33.0713 28.2347 33.0713ZM15.7651 11.8171C13.5429 11.8171 11.7347 13.6369 11.7347 15.8734V27.2694C11.7347 29.5059 13.5429 31.3255 15.7651 31.3255H28.2347C30.4569 31.3255 32.2653 29.5059 32.2653 27.2694V15.8734C32.2653 13.6369 30.4571 11.8171 28.2347 11.8171H15.7651Z"
      fill="white"
    />
    <path
      d="M22 27.8967C18.5195 27.8967 15.6881 25.047 15.6881 21.544C15.6881 18.041 18.5195 15.1916 22 15.1916C25.4805 15.1916 28.3119 18.0412 28.3119 21.544C28.3119 25.0468 25.4805 27.8967 22 27.8967ZM22 16.9374C19.4761 16.9374 17.4227 19.0039 17.4227 21.5438C17.4227 24.0837 19.4761 26.1506 22 26.1506C24.5239 26.1506 26.5773 24.0839 26.5773 21.5438C26.5773 19.0037 24.5239 16.9374 22 16.9374Z"
      fill="white"
    />
    <path
      d="M29.0124 15.8027C29.5792 15.8027 30.0386 15.3404 30.0386 14.77C30.0386 14.1996 29.5792 13.7372 29.0124 13.7372C28.4457 13.7372 27.9862 14.1996 27.9862 14.77C27.9862 15.3404 28.4457 15.8027 29.0124 15.8027Z"
      fill="white"
    />
  </svg>
);

const tiktokIcon = (
  <svg
    width="35"
    height="35"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="black" />
    <path
      d="M22.9795 11.0904C24.1723 11.0713 25.3551 11.0808 26.543 11.0713C26.5777 12.4363 27.1493 13.7392 28.1383 14.7177C29.2068 15.6388 30.5686 16.1924 31.9999 16.2831V19.8102C30.6779 19.7815 29.3758 19.4904 28.1731 18.9606C27.6612 18.7267 27.1692 18.4547 26.697 18.1492C26.6871 20.7027 26.707 23.2608 26.6771 25.8047C26.6076 27.0408 26.1801 28.234 25.4496 29.2506C24.222 30.9545 22.2241 31.9997 20.0622 32.0617C18.7601 32.119 17.4629 31.804 16.3447 31.1597C14.476 30.0954 13.2434 28.2436 13.0198 26.1627C12.9999 25.7236 12.99 25.2892 13.0099 24.8597C13.3826 21.242 16.7423 18.5979 20.5144 18.9558C20.6635 18.9702 20.8126 18.9892 20.9617 19.0131C20.9816 20.3065 20.9269 21.6047 20.9269 22.8981C19.2968 22.3492 17.5126 23.1702 16.9411 24.7356C16.9411 24.7404 16.9411 24.7452 16.9361 24.7499C16.792 25.2081 16.7522 25.6854 16.8069 26.1579C17.0504 27.6517 18.4221 28.7304 19.9976 28.6683C21.0313 28.6397 21.9806 28.1099 22.5223 27.2604C22.7261 26.9883 22.8553 26.6686 22.895 26.3345C22.9845 24.769 22.9497 23.2083 22.9596 21.6429C22.9696 18.1158 22.9497 14.5983 22.9795 11.0808V11.0904Z"
      fill="white"
    />
  </svg>
);

const youtubeIcon = (
  <svg
    width="35"
    height="35"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="black" />
    <path
      d="M34.4564 28.1897C34.158 29.2958 33.276 30.1668 32.1577 30.4629C30.1309 31 22 31 22 31C22 31 13.8691 31 11.8423 30.4629C10.724 30.1668 9.84205 29.2958 9.54364 28.1897C9 26.1835 9 22 9 22C9 22 9 17.8165 9.54364 15.8103C9.84205 14.7042 10.724 13.8332 11.8423 13.5371C13.8706 13 22 13 22 13C22 13 30.1309 13 32.1577 13.5371C33.276 13.8332 34.158 14.7042 34.4564 15.8103C35 17.815 35 22 35 22C35 22 35 26.1835 34.4564 28.1897ZM19.3409 18.2011V25.7989L26.1364 22L19.3409 18.2011Z"
      fill="white"
    />
  </svg>
);

export {
  androidIcon,
  facebookIcon,
  instagramIcon,
  iosIcon,
  linkedinIcon,
  tiktokIcon,
  youtubeIcon,
};
