import ErrorLayout from "@app/layouts/ErrorLayout/ErrorLayout";
import AdSettings from "@app/utils/AdSettings";
import MetaData from "@components/Modules/MetaData";
import {
  isSocialVideo,
  mapVideoStoryTeaserToBlock,
  VideoLandingData,
} from "@pages/VideoLanding/utils";
import {
  CustomContext,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { useLocation } from "react-router-dom";

import PubBaseLayout from "./BaseLayout";
import VideoLandingLayout from "./VideoLandingLayout/VideoLanding";

export default function VideoLandingPage() {
  const routeContext: CustomContext<
    TRouteWithRedirect<VideoLandingData, string>
  > = useRouteContext();
  const { context: data } = routeContext;
  const location = useLocation();

  if (data.statusCode !== 200) {
    return <ErrorLayout response={data} />;
  }

  // For anchorList
  const anchorListData = data.payload.videoLists.find(
    (list) => list.anchor === "videoplaylist",
  );
  const anchorListWithEmbedUrls = anchorListData?.items.map((item) => {
    if (isSocialVideo(item)) {
      return {
        ...item,
        caption: item.title,
      };
    }

    return {
      ...mapVideoStoryTeaserToBlock(item),
      videoUrl: item.embedUri,
      sections: item.sections,
      publishedDate: item.publishDate,
      description: item.description,
    };
  });

  // For socialList
  const socialListData = data.payload.videoLists.find(
    (list) => list.anchor === "socialvideos",
  );
  const socialListWithEmbedUrls = socialListData?.items.filter(isSocialVideo);

  return (
    <PubBaseLayout>
      <MetaData title="Videos" type="WebPage" path="/videos" />
      <AdSettings
        pageType="videos"
        sectionName="videos"
        adUnits="lb1,lb2,catfish,prestitial"
        pageTitle="Videos"
        currentLocation={location}
      />
      <VideoLandingLayout
        data={{
          name: data.payload.videoLandingTitle,
          anchorListTitle: anchorListData?.title,
          anchorList: anchorListWithEmbedUrls,
          socialListTitle: socialListData?.title,
          socialList: socialListWithEmbedUrls,
        }}
      />
    </PubBaseLayout>
  );
}
