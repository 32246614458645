import { RoutePathsType } from "../types/routePaths";

export const RoutePaths: RoutePathsType = {
  NavBar: {
    primaryLinks: [
      {
        name: "POV",
        url: "/pov",
      },
      {
        name: "INDEPENDENCE",
        url: "/independence",
      },
      {
        name: "WELLNESS",
        url: "/wellness",
      },
      {
        name: "LIFE",
        url: "/life",
      },
      {
        name: "STYLE",
        url: "/style",
      },
      {
        name: "SHOP",
        url: "/shopping",
      },
    ],
    utilityLinks: [
      {
        id: "magazineIssues", // allow overwrite of name and url from data
        name: "Subscribe",
        url: "https://subscribe.sph.com.sg/package/subscribe-her-world-singapore/",
        as: "button",
        variant: "primary",
        target: "_blank",
      },
    ],
    searchBarLink: {
      name: "Search",
      url: "/search",
    },
    mobileUtilityLinks: {
      // login: {
      //   name: "Login",
      //   url: "/",
      // },
    },
  },
  Footer: [
    {
      text: "Contact Us",
      url: "https://www.sph.com.sg/contact-us/?ctopic=advertising",
      target: "_blank",
    },
    {
      text: "Advertise with us",
      url: "https://www.sph.com.sg/advertising-solutions/",
      target: "_blank",
    },
    {
      text: "PDPA",
      url: "https://www.sph.com.sg/legal/pdpa/",
      target: "_blank",
    },
    {
      text: "Conditions of Access",
      url: "https://www.sph.com.sg/legal/website_tnc/",
      target: "_blank",
    },
    {
      text: "Privacy Statement",
      url: "https://www.sph.com.sg/legal/sph_privacy/",
      target: "_blank",
    },
  ],
  MegaMenu: [
    {
      text: "POV",
      url: "/pov",
      placement: "left",
      subMenu: [
        {
          text: "People",
          url: "/pov/people",
        },
        {
          text: "Opinions",
          url: "/pov/opinions",
        },
        {
          text: "Features",
          url: "/pov/features",
        },
      ],
    },
    {
      text: "WELLNESS",
      url: "/wellness",
      placement: "left",
      subMenu: [
        {
          text: "Body",
          url: "/wellness/body",
        },
        {
          text: "Mind",
          url: "/wellness/mind",
        },
        {
          text: "Sex & R/S",
          url: "/wellness/sexandrelationships",
        },
      ],
    },
    {
      text: "STYLE",
      url: "/style",
      placement: "left",
      subMenu: [
        {
          text: "Fashion",
          url: "/style/fashion",
        },
        {
          text: "Watches & Jewellery",
          url: "/style/watches-jewellery",
        },
        {
          text: "Beauty",
          url: "/style/beauty",
        },
        {
          text: "Awards",
          url: "/style/awards",
        },
      ],
    },
    {
      text: "INDEPENDENCE",
      url: "/independence",
      placement: "right",
      subMenu: [
        {
          text: "Career",
          url: "/independence/career",
        },
        {
          text: "Money",
          url: "/independence/money",
        },
      ],
    },
    {
      text: "LIFE",
      url: "/life",
      placement: "right",
      subMenu: [
        {
          text: "Entertainment",
          url: "/life/entertainment",
        },
        {
          text: "Travel",
          url: "/life/travel",
        },
        {
          text: "Food & Drink",
          url: "/life/foodanddrink",
        },
        {
          text: "Weddings",
          url: "/life/weddings",
        },
        {
          text: "Stuff to do",
          url: "/life/stuff-do",
        },
      ],
    },
    {
      text: "SHOP",
      url: "/shopping",
      placement: "right",
      subMenu: [
        {
          text: "Deals",
          url: "/shopping/deals",
        },
        {
          text: "Gift Guides",
          url: "/shopping/giftguides",
        },
        {
          text: "Tech & Home",
          url: "/shopping/techhome",
        },
      ],
    },
    {
      text: "WOMEN OF THE YEAR",
      url: "/topics/women-of-impact",
      placement: "left",
      subMenu: [],
    },
    {
      text: "VIDEOS",
      url: "/video",
      placement: "left",
      subMenu: [],
    },
  ],
};
