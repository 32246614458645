import landscapeSrc from "./landscape.svg";
import portraitSrc from "./portrait.svg";

const hwPlaceholders = {
  landscape: {
    src: landscapeSrc,
    srcWidth: 1080,
    srcHeight: 720,
    alt: "",
  },
  portrait: {
    src: portraitSrc,
    srcWidth: 768,
    srcHeight: 1024,
    alt: "",
  },
  square: {
    src: portraitSrc,
    srcWidth: 315,
    srcHeight: 315,
    alt: "",
  },
};

export default hwPlaceholders;
