import "@sphtech/dmg-design-system/dist/theme-hw.css";

import StNewsticker from "@components/Modules/StNewsticker/StNewsticker";
import { configSite } from "@pub/config";
import { BaseLayout } from "@sphtech/dmg-design-system";
import { Helmet } from "react-helmet-async";

import { configHeader } from "./config/configHeader";
import fontsCssString from "./fonts.css?raw";
import logoSvgSource from "./logo.svg?raw";
import { RoutePaths } from "./routePaths";

type HWBaseLayoutProps = {
  ArticleTitle?: string;
  children: React.ReactNode;
  ads?: boolean;
  leaderboard?: boolean;
};

/**
 * Reusable Base layout specific to HW.
 */
const HWBaseLayout = ({
  ArticleTitle,
  children,
  ads = true,
  leaderboard = true,
}: HWBaseLayoutProps) => {
  const currentYear = new Date().getFullYear();
  return (
    <BaseLayout
      ArticleTitle={ArticleTitle}
      ads={ads}
      leaderboard={leaderboard}
      NewsTicker={<StNewsticker />}
      RoutePaths={RoutePaths}
      svgSource={logoSvgSource}
      siteName={configSite.name}
      mciText={
        <span suppressHydrationWarning>
          MCI (P) 022/12/2023. Published by SPH Media Limited, Co. Regn. No.
          202120748H. Copyright © {currentYear} SPH Media Limited. All rights
          reserved.
        </span>
      }
      socialIconProps={configHeader.socialMedia}
      megaMenuProps={{
        NewsLetterProps: configHeader.megaMenu.newsletter,
        CloseButtonProps: configHeader.megaMenu.closeButton,
      }}
    >
      <Helmet>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#b30010" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="theme-color" content="#ffffff" />
        <style type="text/css">{fontsCssString}</style>
      </Helmet>
      {children}
    </BaseLayout>
  );
};

export default HWBaseLayout;
